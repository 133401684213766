<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">Написать заключение</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button type="success">{{$t('message.save_and_close')}}</el-button>
              <el-button>{{$t('message.close')}}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="Editor_widht">
      <div class="userList_infoDoc">
        <div class="itme_userList_infoDoc">
          <span class="ai">Доктор (фио):</span>
          <span class="bi">Alisher O'rinov</span>
        </div>
        <div class="itme_userList_infoDoc">
          <span class="ai">Создан в :</span>
          <span class="bi">2020-01-01 06:00:00</span>
        </div>
      </div>
      <Tinymce ref="editor" class="naw_Editor" v-model="form.header_template" :height="700" />
    </div>
  </div>
</template>
<script>
import Tinymce from "@/components/Tinymce";
export default {
  data() {
    return {
      form: []
    };
  },
  components: {
    Tinymce
  }
};
</script>